//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { action, makeObservable, observable } from 'mobx'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Root Store
import RootStore from '../../index'

// Store Base
import MgmtStoreBase from '../../base/MgmtStoreBase'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Interface
export interface ConfigProps {
  key?: string
  searchable_key?: string
  value?: string
  type?: string
  possible_values?: string
  description?: string
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default class ConfigStore extends MgmtStoreBase<ConfigProps> {
  optionKeyMap: Map<string, number> = new Map()
  elements: Array<ConfigProps> = []
  configLoaded: boolean = false

  root?: RootStore

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(root: RootStore, apiUrl: string) {
    super({ storeName: 'environment', apiUrl: `mgmt/env/${apiUrl}`, _root: root })
    this.root = root
    makeObservable(this, {
      optionKeyMap: observable,
      elements: observable,
      configLoaded: observable,
      setElements: action,
      _updateElements: action,
      setConfigOption: action,
      setConfigLoadedFlag: action,
    })
  }
  getConfig = (key: string) => {
    const optionIndex = this.optionKeyMap.get(key.toLowerCase()) as number
    const config = typeof optionIndex === 'number' ? this.elements[optionIndex] : null
    return config
  }

  getElements = async (path: string) => {
    const { items } = await super.getAll(path)
    this.setElements(items as Array<ConfigProps>)
    this.setConfigLoadedFlag(true)
    return items as Array<ConfigProps>
  }

  updateElement = async (elements: Array<ConfigProps>) => {
    await super.putCustom({ element: { items: elements } })
    this._updateElements(elements)
    return
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  setElements = (elements: Array<ConfigProps>) => {
    this.elements = elements
    elements.forEach(({ key }, index) => this.optionKeyMap.set(key!.toLowerCase(), index))
  }

  _updateElements = (elements: Array<ConfigProps>) => {
    elements.forEach(({ key, value }) => {
      const optionIndex = this.optionKeyMap.get(key!.toLowerCase()) as number
      this.elements[optionIndex].value = value!
    })
  }

  setConfigOption = (key: string, value: any) => {
    const optionIndex = this.optionKeyMap.get(key.toLowerCase()) as number
    this.elements[optionIndex].value = value
  }

  setConfigLoadedFlag = (flag: boolean) => {
    this.configLoaded = flag
  }
}
