import React, { FC, RefObject } from 'react'
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core'
import { RouteComponentProps } from 'react-router'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { ReactEmptyEvent } from '@/helper/define'
import Intl from '@/helper/Intl'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
import { UserForm, UserAssignedGroup, UserControl, CustomInfo } from './partial'
import { Button } from '@/components/_primes'
import { UserRouterParams } from '@/pages/setting/mgmt/users/UserRouter'
import { UserBaseCProps } from './UserBaseContainer'
import MgmtBaseFormStyle from '@/styles/management/MgmtBaseFormStyle'
import { MgmtError } from '@/components/common/Template'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
interface UserBaseFCProps extends WithStyles<typeof styles>, RouteComponentProps<UserRouterParams>, UserBaseCProps {
    userFormRef: RefObject<any>
    userAssignedGroupRef: RefObject<UserAssignedGroup>
    userCtrRef: RefObject<any>
    userCustomInfoRef: RefObject<any>
    onSubmitClick: ReactEmptyEvent
    onCacelClick: ReactEmptyEvent
    onRemoveClick: ReactEmptyEvent
    showNotFoundDialog: (err: MgmtError, removeRequired?: boolean) => void
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const UserBase: FC<UserBaseFCProps> = ({ classes, userId, onChildComponentMount, ...props }) => {
    const btnAdd = Intl.getIntlText({ id: 'htmljs_react.mgmt.userbase.btn_add' })
    const btnUpdate = Intl.getIntlText({ id: 'htmljs_react.mgmt.userbase.btn_update' })
    const btnCancel = Intl.getIntlText({ id: 'htmljs_react.mgmt.userbase.btn_cancel' })
    const btnText = userId ? btnUpdate : btnAdd

    return (
        <>
            <UserForm
                ref={props.userFormRef}
                userId={userId}
                userElement={props.userElement}
                onChildComponentMount={onChildComponentMount}
                showSnackbar={props.showSnackbar}
            />
            <UserAssignedGroup
                ref={props.userAssignedGroupRef}
                userId={userId}
                onChildComponentMount={onChildComponentMount}
                showNotFoundDialog={props.showNotFoundDialog}
            />
            <CustomInfo
                ref={props.userCustomInfoRef}
                userId={userId}
                showSnackbar={props.showSnackbar}
                userElement={props.userElement}
                onChildComponentMount={onChildComponentMount}
            />
            <UserControl
                ref={props.userCtrRef}
                userId={userId}
                userElement={props.userElement}
                onChildComponentMount={onChildComponentMount}
                showSnackbar={props.showSnackbar}
                onRemoveClick={props.onRemoveClick}
            />
            <div className={classes.BtnWrapperDivider} />
            <div className={classes.BtnOuterWrapper}>
                <div className={classes.BtnInnerWrapper}>
                    <div className={classes.BtnCtrWrapper}>
                        <Button btnText={btnText} onClick={props.onSubmitClick} buttonType="task" data-cy-btn="task" />
                    </div>
                    <Button btnText={btnCancel} onClick={props.onCacelClick} data-cy-btn="cancel" />
                </div>
            </div>
        </>
    )
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const styles = (theme: Theme) =>
    createStyles({
        ...MgmtBaseFormStyle(theme),
    })

export default withStyles(styles, { withTheme: true })(UserBase)
