import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core'
import React, { FC, RefObject, useEffect, useRef, useState } from 'react'

import Intl from '@/helper/Intl'
import Dialog from '@/components/common/Dialog'
import { GREY_COLOR } from '@/styles/Theme'
import { Refresh } from '@/components/_primes'

interface CustomInfoDialogFCProps extends WithStyles<typeof styles> {
  dialogRef: RefObject<Dialog>
  onCancel?: () => Promise<{}>
  onConfirm?: () => Promise<{}>
  auth2_data: { url: string; secret: string; code: string }
}

const TwoFactorAuthDialog: FC<CustomInfoDialogFCProps> = ({ classes, ...props }) => {
  const [auth2_data, setAuth2data] = useState(props.auth2_data)
  const [codeInputs, setCodeInputs] = useState(['', '', '', '', '', ''])
  const inputRefs = useRef<Array<HTMLInputElement | null>>([])

  useEffect(() => {
    props.dialogRef.current!.state = auth2_data
  }, [auth2_data])

  const handleInputChange = (index: number, value: string) => {
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newCodeInputs = [...codeInputs]
      newCodeInputs[index] = value
      setCodeInputs(newCodeInputs)

      const enteredCode = newCodeInputs.join('')
      setAuth2data({ ...auth2_data, code: enteredCode })

      if (index > 0 && value === '') {
        inputRefs.current[index - 1]?.focus()
      } else if (index === 0 && value === '') {
        // Do nothing, focus will stay on the current input
      } else if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus()
      }
    }
  }

  const handleReset = () => {
    setCodeInputs(['', '', '', '', '', '']);
    setAuth2data({ ...auth2_data, code: '' });
    inputRefs.current.forEach((input) => {
        if (input) input.value = '';
    });
};

  // confirm 시 초기화
  const onConfirm = async (): Promise<{}> => {
    if (props.onConfirm) {
      await props.onConfirm();
      inputRefs.current[0]?.focus()
    }
    handleReset();
    return {};
  };  

   // cancel 시 초기화
   const onCancel = async (): Promise<{}> => {
    if (props.onCancel) {
      await props.onCancel();
      inputRefs.current[0]?.focus()
    }
    handleReset();
    return {};
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'ArrowLeft' && index > 0) {
      event.preventDefault()
      inputRefs.current[index - 1]?.focus()
    } else if (event.key === 'ArrowRight' && index < inputRefs.current.length - 1) {
      event.preventDefault()
      inputRefs.current[index + 1]?.focus()
    } else if (event.key === 'Backspace' && index > 0 && codeInputs[index] === '') {
      event.preventDefault()
      inputRefs.current[index - 1]?.focus()
    } else if (event.key === 'Enter') { 
      onConfirm()
    } 
  }

  const descOTPCode = Intl.getIntlText({ id: 'htmljs_react.login.twofactorauthdialog.desc_two_factor_code' })
  const titleOTPCode = Intl.getIntlText({ id: 'htmljs_react.login.twofactorauthdialog.otp_code' })
  return (
    <Dialog ref={props.dialogRef} onCancel={onCancel} onConfirm={onConfirm}>
      <div className={classes.MiddleTitleWrapper}>{titleOTPCode}</div>
      <div className={classes.DialogContent}>{descOTPCode}</div>
      <div className={classes.RowWrapper}>
        <div className={classes.InputWrapper}>
          {codeInputs.map((input, index) => (
            <input
              autoComplete="off"
              key={index}
              ref={(ref) => (inputRefs.current[index] = ref)}
              id={`auth2_code_${index}`}
              type='text'
              maxLength={1}
              className={classes.input}
              value={input}
              autoFocus={index === 0}
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyPress(e, index)}
            />
          ))}
          <button className={classes.RefreshButton} onClick={handleReset}><Refresh/></button>
        </div>
      </div>
    </Dialog>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    MiddleTitleWrapper: {
      color: '#fff',
      paddingLeft: '4px',
      paddingRight: '4px',
      paddingBottom: '8px',
      fontSize: '1.3rem',
      fontWeight: 'bold',
      margin: `${theme.spacing(1)}px`,
      borderBottom: `1px solid ${GREY_COLOR}`,
    },
    DialogContent: {
      paddingInline: '4px',
      color: '#ccc',
      margin: `${theme.spacing(1)}px`,
      paddingBottom: '8px'
    },
    FormWrapper: {
      overflow: 'hidden',
    },
    RowWrapper: {
      display: 'flex',
    },
    QrCode: {
      justifyContent: 'center',
      width: '100%',
      textAlign: 'center',
    },
    InputWrapper:{
      margin: '0 auto',
      paddingBottom: '30px',
    },
    RefreshButton: {
      background: 'none',
      border: 'none',
      padding: 0,
      cursor: 'pointer',
      outline: 'none',
      color: 'white',
      lineHeight: '1',
      textAlign: 'center',
      verticalAlign: 'middle', 
      '&:hover': {
        opacity: 0.7
      },
    },
    input: {
      width: '40px',
      height: '40px',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      textAlign: 'center',
      margin: '0 5px',
      padding: '0',
      border: '2px solid #333',
      borderRadius: '4px',
      outline: 'none',
      transition: 'border-color 0.3s ease-in-out',
      backgroundColor: '#f8f9fa',
      '&:focus': {
        borderColor: '#007bff',
        backgroundColor: '#fff',
      },
      '&::placeholder': {
        color: '#999',
      },
    },
  })

export default withStyles(styles, { withTheme: true })(TwoFactorAuthDialog)
