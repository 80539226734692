import React from 'react'
import SnackbarContent, { SnackbarContentProps } from '@material-ui/core/SnackbarContent'
import { IconButton, CloseIcon, InfoIcon, ErrorIcon, CheckCircleIcon, WarningIcon } from '../index'
import withStyles from '@material-ui/core/styles/withStyles'
import styled from 'styled-components'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Event Type
import { SNACK_BAR_EVT, KeyIndexedObject } from '@/helper/define'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Properties of SnackbarContent component
 */
interface SnackbarContentFCProps extends SnackbarContentProps {
    evtType: SNACK_BAR_EVT
    onClose: () => void
    noticeFrom?: string
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * SnackbarContent component
 */
const CoreSnackbarContent = ({
    evtType,
    message,
    onClose,
    noticeFrom,
    ...other
}: SnackbarContentFCProps) => {
    const variantIcon: KeyIndexedObject<React.ComponentType<SvgIconProps>> = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    }
    const Icon = variantIcon[evtType]

    return (
        <CorelineSnackbar
            className={evtType}
            aria-describedby="client-snackbar"
            message={
                <CorelineMessage id="client-snackbar" data-cy-snackbar={noticeFrom || evtType} data-cy-err={evtType === 'error' ? true : false}>
                    <Icon />
                    {message}
                </CorelineMessage>
            }
            action={[
                <CorelineIconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
                    <CorelineCloseIcon />
                </CorelineIconButton>,
            ]}
            {...other}
        />
    )
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Styles
const CorelineSnackbar = withStyles({
    root: {
        width: '100%',
        maxWidth: '630px',
        color: '#fff',
        '&.success': {
            backgroundColor: '#43a047',
        },
        '&.error': {
            backgroundColor: '#d32f2f',
        },
        '&.info': {
            backgroundColor: '#145c82',
        },
        '&.warning': {
            backgroundColor: '#ffa000',
        },
        '&>div:last-child': {
            paddingLeft: '0',
        },
    },
})(SnackbarContent)

const CorelineIconButton = withStyles({
    root: {
        padding: '7px',
    },
})(IconButton)

const CorelineCloseIcon = withStyles({
    root: {
        fontSize: 20,
    },
})(CloseIcon)

const CorelineMessage = styled.span`
    display: flex;
    align-items: center;
    font-size: 1rem;
    & > svg {
        font-size: 20;
        opacity: 0.9;
        margin-right: 8px;
    }
`

export default CoreSnackbarContent
