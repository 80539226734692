import axios from 'axios'

// Define
import { DataStorage } from '@/helper/define/dataStorageDefine'

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// # CRUD
interface ResGetDataStorage {
  items: DataStorage[]
}

export const getDataStorageList = async (query: string = '') => {
  return await axios.get<ResGetDataStorage>(`v1/mgmt/datastorages${query}`)
}

export const getDataStorage = async (id: string) => {
  return await axios.get<DataStorage>(`v1/mgmt/datastorages/${id}`)
}

export const createDataStorage = async (props: DataStorage) => {
  return await axios.post(`v1/mgmt/datastorages`, { ...props })
}

export const updateDataStorage = async ({ id, ...props }: DataStorage) => {
  return await axios.put(`/api/v1/mgmt/datastorages/${id}`, { ...props })
}

export const deleteDataStorage = async (id: string) => {
  return await axios.delete(`/api/v1/mgmt/datastorages/${id}`)
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const checkStoragePath = async (path: string) => {
  return await axios.get(`v1/mgmt/datastorages-check/storage-path?dir_path=${path}`)
}
